<template>
  <div>
    
      <img src="@/assets/img/home/RevelaciónBebe/31.jpg" class="w-100 h-100 img-background" alt="Novios abrazados">
    
    <HomeCard v-for="(card, index) in cards" :datos="card" :key="index" :id="'home-card-'+index" class="mvh-lg-100" />
  </div>
</template>
<script>
  import HomeCard from '@/components/home/Card.vue'
  import Img1 from '@/assets/img/home/RevelaciónBebe/28.jpg'
  import Img1_2 from '@/assets/img/home/2-1.png'
  import Img1_3 from '@/assets/img/home/2-2.png'

  import Img2 from '@/assets/img/home/RevelaciónBebe/29.jpg'
  import Img2_2 from '@/assets/img/home/3-1.png'

  import Img3 from '@/assets/img/home/RevelaciónBebe/30.jpg'
  import Img3_2 from '@/assets/img/home/4-1.png'
  
  export default {
    name: 'Home',
    components: {
      HomeCard
    },
    data(){
      return {
        cards: [{
          title: 'Celebra con Nosotros si es Niño o Niña',
          descripcion: " La celebración de revelación del sexo del bebé, también conocida como gender reveal party, es un evento en el que los futuros padres revelan a sus amigos y familiares el sexo de su bebé. Este tipo de celebraciones se ha vuelto muy popular en los últimos años y tiene varios significados y propósitos: Celebración de una Nueva Vida: Es una forma de celebrar la llegada de un nuevo miembro a la familia. Los padres comparten su alegría y emoción con sus seres queridos. Creación de Recuerdos: Proporciona una oportunidad para crear recuerdos duraderos. Las fotos y videos de la revelación a menudo se atesoran y se muestran al niño cuando crece. Fortalecimiento de Vínculos: Reunir a amigos y familiares para este evento fortalece los lazos y crea un sentido de comunidad y apoyo para los futuros padres. Suspenso y Sorpresa: Añade un elemento de sorpresa y emoción al proceso del embarazo. Tanto los padres como los invitados suelen estar ansiosos por descubrir el sexo del bebé, lo que hace que el momento de la revelación sea especialmente memorable. Creatividad y Diversión: Estas celebraciones permiten a los padres ser creativos en la forma en que eligen revelar el sexo del bebé. Desde globos que explotan con confeti de colores hasta pasteles con relleno de color, las opciones son variadas y divertidas.",
          img1: Img1,
          img2: Img1_2,
          img3: Img1_3,
          bg: 'bg-primary text-white',
          align: 'end',
          url: 'como-funciona.index',
          urlname: 'Como funciona',
          urlClass: 'btn-light',
          hide: true
        },
        {
          title: 'A la espera de Ti',
          descripcion: ' En la suave brisa de la mañana, en el canto dulce del gorrión, late un secreto en nuestras almas, una nueva vida, una ilusión. Te aguardamos con ansias y ternura, pequeña estrella en nuestro cielo, cada día crece más la dulzura, al pensar en tu arrullo y en tu anhelo. Tus pasos aún son sueños y quimeras, tus risas, ecos del porvenir, pero ya llenas nuestras horas enteras, con la promesa de tu existir. Pequeño ser, fruto de amor sincero, eres milagro, esperanza y fe, en cada latido, te siento más cercano, como un susurro, como un por qué. Nuestras manos te esperan, siempre abiertas, nuestros corazones, llenos de amor, aquí tendrás un hogar de puertas donde hallarás cobijo y calor. A la espera de ti, dulce presencia, nuestros días se llenan de canción, eres la dicha, la esencia, la razón de nuestra bendición.',
          img1: Img2,
          img2: Img2_2,
          bg: 'bg-secondary text-white',
          align: 'start',
          hide: true,
        },
        {
          title: 'Comparte cada Momento',
          descripcion: ' Queridos amigos y familiares, No tenemos palabras suficientes para expresar lo agradecidos que estamos por su presencia en este día tan especial. Su amor y apoyo significan el mundo para nosotros mientras celebramos la revelación del sexo de nuestro bebé. Gracias por compartir este momento de alegría y emoción con nosotros. Cada uno de ustedes tiene un lugar especial en nuestros corazones, y estamos muy felices de poder contar con su compañía mientras descubrimos juntos si esperamos a un niño o una niña. Agradecemos sus buenos deseos, sus abrazos cálidos y las risas compartidas. Su presencia hace que este día sea aún más memorable y lleno de felicidad. Nos sentimos bendecidos por tenerlos a nuestro lado en esta maravillosa etapa de nuestras vidas. Con todo nuestro cariño y gratitud, ',
          img1: Img3,
          img2: Img3_2,
          bg: 'bg-light text-dark',
          align: 'end',
          hide: true
        }]
      }
    },
    mounted(){
      window.addEventListener('scroll', this.handleScroll);
    },
    unmounted(){
      window.removeEventListener('scroll', this.handleScroll)
    },
    methods: {
      handleScroll () {
        for (let index = 0; index < this.cards.length; index++) {
          var top = document.getElementById('home-card-'+index).offsetTop
          if(document.documentElement.scrollTop >= (top-20)){
            if(this.cards[index]['hide']){
              this.cards[index]['hide'] = false
            }
          }
        }
      }
    }
  }
</script>